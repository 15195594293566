import React from 'react'
import { View } from 'react-native'
import { useMedia } from 'models/Medium'
import { MediumList } from 'components/Medium/List'
import PageHeader from 'components/Shared/PageHeader'

export default function Affiliate() {
  const { media, mutate } = useMedia({ status: { value: 0 } })
  return (
    <View>
      {media && media.length > 0 && (
        <PageHeader title="承認待ちメディア">
          <MediumList media={media} onMediumUpdated={mutate} />
        </PageHeader>
      )}
    </View>
  )
}
