import { OwnerInvoiceResource_Status } from '@hello-ai/proto/src/gen/auto_reserve/admin/owner_invoice/owner_invoice_resource'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import React, { useState } from 'react'

import { FilterAction } from 'components/Filter/FilterAction'
import { getStatusName } from 'models/OwnerInvoice'

const STATUSES = [
  OwnerInvoiceResource_Status.DRAFT,
  OwnerInvoiceResource_Status.BILLED,
  OwnerInvoiceResource_Status.PAID,
  OwnerInvoiceResource_Status.NOT_BILLED,
].map((status) => ({ value: status, text: getStatusName(status) }))

export function FilterStatus({
  onFilter,
  defaultValue,
}: {
  onFilter: (value: number[]) => void
  defaultValue: number[]
}) {
  const [newSelectedStatus, setNewSelectedStatus] = useState(defaultValue)

  return (
    <FormGroup sx={{ p: 2 }}>
      {STATUSES.map((option) => {
        const checked = newSelectedStatus.includes(option.value)

        return (
          <FormControlLabel
            key={option.text}
            control={<Checkbox />}
            label={option.text}
            checked={checked}
            onChange={(_, newChecked) => {
              if (newChecked === checked) return
              setNewSelectedStatus((prev) => {
                if (newChecked) {
                  return [...prev, option.value]
                } else {
                  return prev.filter(
                    (selectedStatus) => selectedStatus !== option.value
                  )
                }
              })
            }}
          />
        )
      })}
      <FilterAction
        onReset={() => {
          setNewSelectedStatus(defaultValue)
        }}
        onSubmit={() => {
          onFilter(newSelectedStatus)
        }}
      />
    </FormGroup>
  )
}
