import React from 'react'
import { View } from 'react-native'
import { useMedia } from 'models/Medium'
import { MediumList } from 'components/Medium/List'
import PageHeader from 'components/Shared/PageHeader'

export default function AffiliateMedia() {
  const { media, mutate } = useMedia({})

  return (
    <View>
      <PageHeader title="メディア一覧">
        <MediumList media={media} onMediumUpdated={mutate} />
      </PageHeader>
    </View>
  )
}
