import React from 'react'
import { useNavigate, useParams } from 'react-router'
import { useMedium, getStatusName } from 'models/Medium'
import Breadcrumb from 'components/Shared/Breadcrumb'
import PageHeader from 'components/Shared/PageHeader'
import { DescriptionItem, Descriptions } from 'components/Shared/Descriptions'
import DataGrid from 'components/Shared/DataGrid'
import { Button } from '@mui/material'

export default function AffiliateMedium() {
  const navigate = useNavigate()
  const { mediumId } = useParams<{ mediumId: string }>()
  const { medium } = useMedium({ id: mediumId! })

  if (medium === undefined) return null

  return (
    <>
      <Breadcrumb
        routes={[
          {
            path: '/affiliate/media',
            breadcrumbName: 'メディア一覧',
          },
          {
            path: `/affiliate/media/${medium.id}`,
            breadcrumbName: medium.name,
          },
        ]}
      />
      <PageHeader
        title={medium.name}
        suffixTitle={getStatusName(medium.status)}
        sx={{ marginTop: '32px' }}
      >
        <Descriptions>
          <DescriptionItem label="サイト名">{medium.name}</DescriptionItem>
          <DescriptionItem label="ステータス">
            {getStatusName(medium.status)}
          </DescriptionItem>
          <DescriptionItem label="ドメイン">{medium.domain}</DescriptionItem>
        </Descriptions>
      </PageHeader>
      <PageHeader title="ユーザー一覧" sx={{ marginTop: 32 }}>
        <DataGrid
          rowKey={(record) => record.id}
          columns={[
            {
              title: '名前',
              key: 'name',
              render: (user) => user.name,
            },
            {
              title: 'メールアドレス',
              key: 'email',
              render: (user) => user.email,
            },
            {
              title: 'アクション',
              key: 'action',
              render: (user) => (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => navigate(`/users/${user.id}`)}
                >
                  詳細
                </Button>
              ),
            },
          ]}
          data={medium.users}
          sx={{ minHeight: 400 }}
        />
      </PageHeader>
    </>
  )
}
