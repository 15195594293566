import {
  MediumResource,
  MediumResource_Status,
} from '@hello-ai/proto/src/gen/auto_reserve/admin/medium/medium_resource'
import { UpdateRequest } from '@hello-ai/proto/src/gen/auto_reserve/admin/medium/medium_service'
import { Box, Button } from '@mui/material'
import ConfirmModal from 'components/Shared/ConfirmModal'
import DataGrid from 'components/Shared/DataGrid'
import { displayToastInfo } from 'components/Shared/Toast'
import dayjs from 'dayjs'
import { onError, useToken } from 'models/Auth'
import { getStatusName, mediumClient } from 'models/Medium'
import { callRpc, rpcOptions } from 'modules/rpc'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export function MediumList({
  media,
  onMediumUpdated,
}: {
  media: MediumResource[] | undefined
  onMediumUpdated: () => void
}) {
  const navigate = useNavigate()
  const token = useToken()

  const [approveMedium, setApproveMedium] = useState<MediumResource | null>(
    null
  )
  const [unapproveMedium, setUnapproveMedium] = useState<MediumResource | null>(
    null
  )

  const approve = useCallback(async () => {
    const medium = approveMedium!
    const params: UpdateRequest = { id: medium.id, status: { value: 1 } }
    const { error } = await callRpc(
      mediumClient.update(params, rpcOptions({ token }))
    )

    if (error != null) {
      onError(error)
      return
    }

    onMediumUpdated()

    displayToastInfo('承認しました')
    setApproveMedium(null)
  }, [approveMedium, onMediumUpdated, token])

  const unapprove = useCallback(async () => {
    const medium = unapproveMedium!
    const params: UpdateRequest = { id: medium.id, status: { value: 2 } }

    const { error } = await callRpc(
      mediumClient.update(params, rpcOptions({ token }))
    )

    if (error != null) {
      onError(error)
      return
    }

    onMediumUpdated()

    displayToastInfo('非承認にしました')
    setUnapproveMedium(null)
  }, [onMediumUpdated, token, unapproveMedium])

  return (
    <>
      <DataGrid
        rowKey={(record) => record.id}
        columns={[
          {
            title: 'アクション',
            key: 'action',
            render: (medium) => (
              <Box>
                {medium.status === MediumResource_Status.UNDER_REVIEW && (
                  <>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => setApproveMedium(medium)}
                    >
                      承認
                    </Button>
                    <Button
                      size="small"
                      color="warning"
                      onClick={() => setUnapproveMedium(medium)}
                    >
                      非承認
                    </Button>
                  </>
                )}
                <Button
                  size="small"
                  onClick={() => navigate(`/affiliate/media/${medium.id}`)}
                >
                  詳細
                </Button>
              </Box>
            ),
          },
          {
            title: 'ステータス',
            key: 'status',
            render: (medium) => getStatusName(medium.status),
          },
          {
            title: 'サイト名',
            key: 'name',
            render: (medium) => medium.name,
          },
          {
            title: 'サイトドメイン',
            key: 'domain',
            render: (medium) => medium.domain,
          },
          {
            title: 'URL',
            key: 'url',
            render: (medium) => medium.url,
          },
          {
            title: '作成日',
            key: 'createdAt',
            render: (medium) =>
              medium.createdAt
                ? dayjs
                    .unix(medium.createdAt?.seconds)
                    .format('YYYY/MM/DD hh:mm')
                : '',
          },
        ]}
        data={media}
        sx={{ minHeight: 400 }}
      />
      <ConfirmModal
        type="warning"
        isVisible={approveMedium !== null}
        title="承認します"
        onClose={() => setApproveMedium(null)}
        onConfirm={approve}
      />
      <ConfirmModal
        type="warning"
        isVisible={unapproveMedium !== null}
        title="非承認します"
        onClose={() => setUnapproveMedium(null)}
        onConfirm={unapprove}
      />
    </>
  )
}
