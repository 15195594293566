import { assertNever } from 'modules/assert'
import { createRpcService, rpcOptions } from 'modules/rpc'
import useSWR, { swrKey } from 'modules/swr'
import { useToken } from './Auth'

import { MediumResource_Status } from '@hello-ai/proto/src/gen/auto_reserve/admin/medium/medium_resource'
import {
  GetRequest,
  ListRequest,
} from '@hello-ai/proto/src/gen/auto_reserve/admin/medium/medium_service'
import { MediumClient } from '@hello-ai/proto/src/gen/auto_reserve/admin/medium/medium_service.client'

export const mediumService = createRpcService(MediumClient)
export const mediumClient = mediumService.client

export function getStatusName(value: MediumResource_Status) {
  switch (value) {
    case MediumResource_Status.UNDER_REVIEW:
      return '審査中'
    case MediumResource_Status.CERTIFIED:
      return '承認'
    case MediumResource_Status.NOT_CERTIFIED:
      return '非承認'
    default: {
      assertNever(value)
    }
  }
}

export function useMedia(params: ListRequest) {
  const token = useToken()

  const { data, mutate, error } = useSWR(
    swrKey(token, `Medium/List/${params.status?.value}`),
    () => mediumClient.list(params, rpcOptions({ token })).response
  )

  return {
    media: data?.media,
    mutate,
    error,
  }
}

export function useMedium(params: GetRequest) {
  const token = useToken()

  const { data, mutate, error } = useSWR(
    swrKey(token, `Medium/Get/${params.id}`),
    () => mediumClient.get(params, rpcOptions({ token })).response
  )

  return {
    medium: data,
    mutate,
    error,
  }
}
