import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import {
  Box,
  CircularProgress,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { SystemStyleObject, Theme } from '@mui/system'
import FilterAltIcon from '@mui/icons-material/FilterAlt'

interface Pagination {
  page: number
  perPage: number
  onChangePage: (page: number) => void
  onChangePerPage: (perPage: number) => void
}

export const usePagination = (perPage = 50): Pagination => {
  const [pagingInfo, setPagingInfo] = useState({
    page: 1,
    perPage,
  })

  const onChangePage = useCallback(
    (page: number) =>
      setPagingInfo((prev) => ({
        ...prev,
        page,
      })),
    []
  )

  const onChangePerPage = useCallback(
    (perPage: number) =>
      setPagingInfo({
        page: 1,
        perPage,
      }),
    []
  )

  return {
    ...pagingInfo,
    onChangePage,
    onChangePerPage,
  }
}

export interface FilterContentProps<Value> {
  onFilter: (value: Value) => void
  defaultValue: Value
}

export interface FilterableTableCellProps<Value = any> {
  title?: ReactNode
  value: Value
  onChangeValue: (newValue: Value) => void
  FilterContent: React.ComponentType<FilterContentProps<Value>>
}

function FilterableTableCell<Value>({
  title,
  value,
  onChangeValue,
  FilterContent,
}: FilterableTableCellProps<Value>) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  return (
    <>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
          {title}
          <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
            <FilterAltIcon />
          </IconButton>
        </Box>
      </TableCell>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <FilterContent
          onFilter={(newValue) => {
            onChangeValue(newValue)
            setAnchorEl(null)
          }}
          defaultValue={value}
        />
      </Popover>
    </>
  )
}

export interface Column<Data, KeyOfData, FilterState> {
  key: KeyOfData
  title?: string | ReactNode
  render: (row: Data) => ReactNode
  filter?: {
    [K in keyof FilterState]: {
      key: K
      FilterContent: React.ComponentType<FilterContentProps<FilterState[K]>>
    }
  }[keyof FilterState]
}

interface DataGridProps<
  Data,
  KeyOfData extends PropertyKey,
  FilterState,
  KeyOfFilterState extends PropertyKey = keyof FilterState
> {
  sx?: SystemStyleObject<Theme>
  columns: Column<Data, KeyOfData, FilterState>[]
  data?: Data[]
  totalCount?: number
  loading?: boolean
  rowKey: (row: Data) => React.Key
  pagination?: Pagination
  filterState?: FilterState
  onChangeFilterValue?: (key: KeyOfFilterState, newValue: any) => void
}

export default function DataGrid<
  Data,
  KeyOfData extends PropertyKey,
  FilterState extends Record<string, any>
>({
  sx,
  columns,
  data,
  totalCount,
  loading,
  rowKey,
  pagination,
  filterState,
  onChangeFilterValue,
}: DataGridProps<Data, KeyOfData, FilterState>) {
  const body = useMemo(() => {
    if (loading === true)
      return (
        <TableRow>
          <TableCell align="center" colSpan={columns.length}>
            <CircularProgress />
          </TableCell>
        </TableRow>
      )
    if (data === undefined || data.length === 0)
      return (
        <TableRow>
          <TableCell align="center" colSpan={columns.length}>
            データがありません
          </TableCell>
        </TableRow>
      )
    return data.map((row) => (
      <TableRow key={rowKey(row)}>
        {columns.map((column, index) => {
          const key = `${String(column.key)}-${index}`
          return (
            <TableCell sx={{ whiteSpace: 'nowrap' }} key={key}>
              {column.render(row)}
            </TableCell>
          )
        })}
      </TableRow>
    ))
  }, [columns, data, loading, rowKey])

  return (
    <>
      <TableContainer sx={sx}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => {
                const key = `${String(column.key)}-${index}`
                const filter = column.filter
                if (filter !== undefined && filterState !== undefined) {
                  return (
                    <FilterableTableCell
                      key={key}
                      title={column.title}
                      value={filterState[filter.key]}
                      onChangeValue={(newValue) => {
                        onChangeFilterValue?.(filter.key, newValue)
                      }}
                      FilterContent={filter.FilterContent}
                    />
                  )
                }
                return (
                  <TableCell sx={{ whiteSpace: 'nowrap' }} key={key}>
                    {column.title}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>{body}</TableBody>
        </Table>
      </TableContainer>
      {pagination !== undefined && (
        <TablePagination
          component="div"
          count={totalCount ?? -1}
          page={pagination.page - 1}
          onPageChange={(_, page) => {
            pagination.onChangePage(page + 1)
          }}
          rowsPerPage={pagination.perPage}
          onRowsPerPageChange={(event) => {
            pagination.onChangePerPage(parseInt(event.target.value))
          }}
        />
      )}
    </>
  )
}
